let maxHeightSchedule = 0;
let maxHeightNote = 0;

$('.schedule__top').each(function(key, item) {
    if (maxHeightSchedule < $(item).innerHeight()) {
        maxHeightSchedule = $(item).innerHeight();
    }
})
$('.schedule__top').css('height', maxHeightSchedule)

$('.note-footer').each(function(key, item) {
    if (maxHeightNote < $(item).innerHeight()) {
        maxHeightNote = $(item).innerHeight();
    }
})
$('.note-footer').css('height', maxHeightNote)
